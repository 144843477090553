import { Check } from "lucide-react";
import ComparisionTableMentor from "./ComparisionTableMentor";
const pricingTiers = [
  {
    name: "Basic",
    price: "1st mentee: £50",
    originalPrice: "2nd mentee onwards: £100",
    description:
      "Perfect for students who want focused mentor feedback on application documentation.",
    features: [
      "Free Session (20-25 minutes)",
      "2 sessions (30-45 minutes)",
      "1 Application",
    ],
  },
  {
    name: "Pro",
    price: "1st mentee: £125",
    originalPrice: "2nd mentee onwards: £250",
    description:
      " Designed for students seeking comprehensive support, maximising chances of success.",
    features: [
      "Free Session (20-25 minutes)",
      "Upto 5 sessions (30-45 minutes)",
      "Upto 2 Applications",
    ],
    popular: true,
  },
  {
    name: "Premium",
    price: "1st mentee: £150",
    originalPrice: "2nd mentee onwards: £300",
    description:
      "Pro plan features + dedicated scholarship and interview guidance along with access to alumni network.",
    features: [
      "Free Session (20-25 minutes)",
      "Upto 6 sessions (30-45 minutes)",
      "Upto 5 Applications",
    ],
    superSaver: true,
  },
  {
    name: "Accelerator session",
    price: "1st mentee: £40",
    originalPrice: "2nd mentee onwards: £75",
    description:
      "Can be added to any plan or taken independently with your chosen mentor for targeted guidance.",
    features: ["1 session (45-60 minutes)"],
  },
];
export default function PricingPageMentor() {
  return (
    <div className="min-h-screen text-black bg-white p-4 md:p-8">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Header */}
        <div className="text-center space-y-4">
          <h1 className="text-4xl md:text-5xl font-bold">Payment plans</h1>
          <h3 className="text-md md:text-xl font-medium">
            {" "}
            Based on the plan selected by the student, you would be paid as
            below
          </h3>
          <p className=" max-w-2xl mx-auto px-2 rounded text-primary text-base bg-orange-200 border border-orange-900">
            Get paid more with your growing experience and positive feedback
            from students.
          </p>
        </div>
        {/* Pricing Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {pricingTiers.map((tier, index) => (
            <div
              key={tier.name}
              className={`rounded-lg border-2 ${
                tier.popular || tier.superSaver
                  ? "border-primary"
                  : "border-gray-200"
              } ${
                tier.superSaver
                  ? "bg-secondary-300"
                  : tier.popular
                  ? "bg-green-200"
                  : "bg-stone-100"
              } p-6 relative`}
            >
              {tier.popular && (
                <div className="absolute -top-3 right-4 bg-red-300 text-primary text-xs py-1 px-3 rounded-full">
                  Most Popular
                </div>
              )}
              {tier.superSaver && (
                <div className="absolute -top-3 right-4 bg-orange-300 text-primary text-xs py-1 px-3 rounded-full">
                  Super Saver
                </div>
              )}
              <h2 className="text-xl font-bold">{tier.name}</h2>
              <p className="text-sm text-primary mb-2">{tier.description}</p>

              <div>
                <div className="flex flex-col items-baseline space-x-1">
                  <div className="text-lg font-semibold mt-1">{tier.price}</div>
                  {tier.originalPrice && (
                    <span className="text-amber-700 font-semibold text-lg mt-1 mb-4 ">
                      {tier.originalPrice}
                    </span>
                  )}
                </div>
              </div>
              <div className="space-y-3">
                <ul className="space-y-3">
                  {tier.features.map((feature, featureIndex) => (
                    <li
                      key={featureIndex}
                      className="flex items-start space-x-3 text-sm"
                    >
                      <Check className="w-5 h-5 text-primary shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ComparisionTableMentor />
    </div>
  );
}
