import { Check, Plus } from "lucide-react";
import ComparisionTable from "./ComparisionTable";
import { useState, useEffect, useRef } from "react";
import supabase from "../../config/supabaseClient";
import { Link } from "react-router-dom";

const pricingTiers = [
  {
    name: "Basic",
    price: "£69",
    originalPrice: "£149",
    savings: "54% off",
    description:
      "Perfect for students who want focused mentor feedback on application documentation.",
    features: [
      "Free Session (20-25 minutes)",
      "2 sessions (30-45 minutes)",
      "1 Application",
    ],
    moreFeatures: 4,
  },
  {
    name: "Pro",
    price: "£149",
    originalPrice: "£349",
    savings: "57% off",
    description:
      " Designed for students seeking comprehensive support, maximising chances of success.",
    features: [
      "Free Session (20-25 minutes)",
      "Upto 6 sessions (30-45 minutes)",
      "Upto 2 Applications",
    ],
    popular: true,
    moreFeatures: 13,
  },
  {
    name: "Premium",
    price: "£199",
    originalPrice: "£499",
    savings: "60% off",
    description:
      "Pro plan features + dedicated scholarship and interview guidance along with access to alumni network.",
    features: [
      "Free Session (20-25 minutes)",
      "Upto 8 sessions (30-45 minutes)",
      "Upto 5 Applications",
    ],
    superSaver: true,
    moreFeatures: 17,
  },
  {
    name: "Accelerator session",
    price: "£49",
    originalPrice: "£99",
    savings: "50% off",
    description:
      "Can be added to any plan or taken independently with your chosen mentor for targeted guidance.",
    features: ["1 session (45-60 minutes)"],
  },
];
export default function PricingPage() {
  const [PaymentDialogue, setPaymentDialogue] = useState(false);
  const [session, setSession] = useState(null);
  const comparisonTableRef = useRef(null);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();
      if (sessionError) {
        console.error("Error fetching session:", sessionError);
        return;
      }

      setSession(sessionData.session);
    };

    fetchSession();
  }, []);

  const scrollToComparisonTable = () => {
    if (comparisonTableRef.current) {
      comparisonTableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="min-h-screen text-black bg-white p-4 md:p-8">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Header */}
        <div className="text-center space-y-4">
          <h1 className="text-4xl md:text-5xl font-bold">
            Plans for everyone.
          </h1>
          <p className=" max-w-sm mx-auto px-2 rounded text-primary text-base bg-orange-200 border border-orange-900">
            All deals are valid untill 31st Dec, 2024.
          </p>
        </div>
        {/* Pricing Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {pricingTiers.map((tier, index) => (
            <div
              key={tier.name}
              className={`rounded-lg border-2 ${
                tier.popular || tier.superSaver
                  ? "border-primary"
                  : "border-gray-200"
              } ${
                tier.superSaver
                  ? "bg-secondary-300 "
                  : tier.popular
                  ? "bg-green-200"
                  : "bg-stone-100"
              } p-6  relative`}
            >
              {tier.popular && (
                <div className="absolute -top-3 right-4 bg-red-300 text-primary text-xs py-1 px-3 rounded-full">
                  Most Popular
                </div>
              )}
              {tier.superSaver && (
                <div className="absolute -top-3 right-4 bg-orange-300 text-primary text-xs py-1 px-3 rounded-full">
                  Super Saver
                </div>
              )}
              <h2 className="text-xl font-bold my-2">{tier.name}</h2>
              <p className="text-sm text-primary">{tier.description}</p>

              <button
                onClick={() => setPaymentDialogue(!PaymentDialogue)}
                className="w-full py-2 px-4 rounded my-4 bg-primary text-white hover:scale-105 transition duration-300"
              >
                Buy now
              </button>

              {PaymentDialogue && (
                <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center p-4 z-50">
                  <div className="relative bg-white text-primary rounded-lg p-6 max-w-sm max-h-[90vh] overflow-y-auto">
                    {session ? (
                      <>
                        <div className="my-4 text-center font-semibold">
                          Get information about payment
                        </div>
                        <div className="flex items-center justify-center space-x-4">
                          <a
                            href="https://wa.me/447440196490"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button className="flex justify-end font-semibold bg-primary px-2 py-1 rounded text-white">
                              Confirm
                            </button>
                          </a>

                          <button
                            onClick={() => setPaymentDialogue(!PaymentDialogue)}
                            className="flex justify-end font-semibold bg-purple-500 px-2 py-1 rounded text-white"
                          >
                            Close
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="my-4 text-center font-semibold">
                          Please login to buy the plan
                        </div>
                        <div className="flex items-center justify-center space-x-4">
                          <Link
                            to="/login"
                            className="font-semibold bg-primary px-2 py-1 rounded text-white"
                          >
                            Login
                          </Link>
                          <button
                            onClick={() => setPaymentDialogue(!PaymentDialogue)}
                            className="flex justify-end font-semibold bg-purple-500 px-2 py-1 rounded text-white"
                          >
                            Close
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              <div>
                <div className="flex items-baseline space-x-1">
                  <span className="text-3xl font-bold my-2">{tier.price}</span>
                  {tier.originalPrice && (
                    <span className="text-orange-600 text-xl line-through">
                      {tier.originalPrice}
                    </span>
                  )}
                  <span className="text-green-600 text-xl ">
                    {tier.savings}
                  </span>
                </div>
              </div>
              <div className="space-y-3">
                <ul className="space-y-3">
                  {tier.features.map((feature, featureIndex) => (
                    <li
                      key={featureIndex}
                      className="flex items-start space-x-3 text-sm"
                    >
                      <Check className="w-5 h-5 text-primary shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                {index < 3 && tier.moreFeatures && (
                  <button
                    className="flex items-center gap-1 text-sm font-bold underline text-primary-600 hover:text-primary-700 mt-2 cursor-pointer "
                    onClick={scrollToComparisonTable}
                  >
                    <Plus className="w-4 h-4 font-bold" />
                    {tier.moreFeatures} More Benefits
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div ref={comparisonTableRef}>
        <ComparisionTable />
      </div>
    </div>
  );
}
