import React from "react";
import Queens from "../assets/images/ForSummer/Queens_punt.jpg";
import Kings from "../assets/images/ForSummer/Kings_back.jpg";
import Student_seminar from "../assets/images/ForSummer/Student_seminar.jpg";
function Summerschool() {
  return (
    <div className="min-h-lvh bg-stone-200 pt-16">
      <div className="flex item-center justify-center text-primary text-5xl font-bold font-league-spartan  px-8 py-4 md:py-8 md:px-20 ">
        Cambridge Summer School 2025
      </div>{" "}
      <div className="bg-secondary-600 text-2xl text-center py-2">
        {" "}
        Paving pathways to success{" "}
      </div>
      <div
        className="p-4 md:px-20 flex md:flex-row md:h-lvh bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)), url(${Queens})`,
        }}
      >
        <div className="text-white pr-4 md:w-1/2 flex flex-col justify-center text-xl md:text-2xl ">
          <div className=" font-semibold">
            Explore the Heart of{" "}
            <span className="text-rose-300">Innovation</span>
          </div>
          <div className=" font-semibold">
            Unlock the legacy of <span className="text-rose-300">History</span>
          </div>
          <div className=" font-semibold">
            Where History Meets{" "}
            <span className="text-rose-300">Opportunity</span>
          </div>
          <div className=" font-semibold">
            Uncover secrets of <span className="text-rose-300">Success</span>
          </div>
          <div className=" font-semibold">
            Step into <span className="text-rose-300">Excellence</span>
          </div>
          <div className=" font-semibold">
            Inspiring <span className="text-rose-300">Young Minds</span>
          </div>
          <div className=" font-semibold">
            Shaping <span className="text-rose-300">Futures</span>
          </div>
          <div className=" font-semibold">
            Discover <span className="text-rose-300">Magic</span>
          </div>

          <div className=" font-semibold py-10 text-5xl">
            This is <span className="text-secondary-600 ">Cambridge</span>
          </div>
        </div>
      </div>
      <div className=" px-6 md:px-20 py-6  text-primary ">
        <div className=" py-10 md:py-20 text-xl md:text-3xl font-medium ">
          Unibridge Summer School program is designed to spark curiosity,
          enhance critical thinking, and build confidence in young minds.
        </div>

        <div className="md:flex">
          <div className="md:w-1/2 my-4">
            <img src={Student_seminar} className="rounded-xl" alt="student" />
          </div>
          <div className="text-md md:text-xl md:w-1/2 md:px-20 text-center flex items-center justify-center">
            Our program features a wide array of engaging activities that foster
            a vibrant atmosphere for students to explore new concepts and
            develop essential skills. Guided by mentors from Cambridge and
            Oxford, participants will experience hands-on workshops,
            collaborative projects, and stimulating discussions, all aimed at
            nurturing a passion for learning and preparing them for future
            success.
          </div>
        </div>

        <div className="flex items-center justify-center md:flex-row flex-col text-center my-10 h-40 bg-primary rounded-xl">
          <span className=" text-xl md:text-3xl text-white font-medium px-4 md:py-0 py-4">
            Find more about the program
          </span>
          <button className="px-8 py-2 bg-white text-primary font-medium text-xl rounded hover:scale-105 transition duration-300 ease-in-out">
            <a
              href="https://drive.google.com/file/d/1rhSqskp6hjC66pZwkt7U_xDpe_SiuuZu/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Brochure
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Summerschool;
