import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import supabase from "../../config/supabaseClient";
import default_profile from "../../assets/images/default_profile.svg";
import Linkedin from "../../assets/images/Linkedin.svg";
import Website_personal from "../../assets/images/Website_personal.svg";
import Google_Scholar from "../../assets/images/Google_Scholar.svg";
import Swal from "sweetalert2";

function MentorDetails() {
  const { userId } = useParams(); // Get the userId from the URL
  const [mentor, setMentor] = useState(null);
  const [menteePreferences, setMenteePreferences] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [session, setSession] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const fetchMentorDetails = async () => {
      try {
        // Initiate all queries in parallel
        const mentorQuery = supabase
          .from("users")
          .select("*")
          .eq("user_id", userId)
          .single();

        const educationQuery = supabase
          .from("user_education")
          .select("*")
          .eq("user_id", userId);

        const experienceQuery = supabase
          .from("user_experience")
          .select("*")
          .eq("user_id", userId);

        const disciplinesQuery = supabase
          .from("user_disciplines")
          .select("*")
          .eq("user_id", userId);

        const websiteQuery = supabase
          .from("user_website")
          .select("*")
          .eq("user_id", userId);

        // Await all queries simultaneously
        const [
          { data: mentorData, error: mentorError },
          { data: educationData, error: educationError },
          { data: experienceData, error: experienceError },
          { data: disciplinesData, error: disciplinesError },
          { data: websiteData, error: websiteError },
        ] = await Promise.all([
          mentorQuery,
          educationQuery,
          experienceQuery,
          disciplinesQuery,
          websiteQuery,
        ]);

        // Handle errors for each query
        if (mentorError) throw mentorError;
        if (educationError) throw educationError;
        if (experienceError) throw experienceError;
        if (disciplinesError) throw disciplinesError;
        if (websiteError) throw websiteError;

        setMentor({
          ...mentorData,
          education: educationData,
          experience: experienceData,
          disciplines: disciplinesData,
          website: websiteData || [], // Ensure website data is an array
        });

        if (mentorData.type === "Mentee") {
          const menteePreferencesQuery = supabase
            .from("umentee_career_preferences")
            .select("*")
            .eq("user_id", userId);

          const menteeUniversityQuery = supabase
            .from("umentee_university_preference")
            .select("*")
            .eq("user_id", userId);

          const [
            { data: menteePreferencesData, error: menteePreferencesError },
            { data: menteeUniversityChoice, error: menteeUniversityError },
          ] = await Promise.all([
            menteePreferencesQuery,
            menteeUniversityQuery,
          ]);

          if (menteePreferencesError) throw menteePreferencesError;
          if (menteeUniversityError) throw menteeUniversityError;

          setMenteePreferences({
            preference: menteePreferencesData,
            university: menteeUniversityChoice,
          });
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMentorDetails();
  }, [userId]);

  const handleConnectClick = async () => {
    const fetchSession = async () => {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();
      if (sessionError) {
        console.error("Error fetching session:", sessionError);
        throw new Error("Error fetching session");
      }
      return sessionData.session;
    };

    try {
      const session = await fetchSession();
      if (!session) {
        Swal.fire({
          title: "Please login to connect to a mentor",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Login",
          cancelButtonText: "Cancel",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login");
          }
        });
        return;
      }

      setSession(session);

      // Check user type
      const { data: userData, error: userError } = await supabase
        .from("users")
        .select("type")
        .eq("user_id", session.user.id)
        .single();
      if (userError) throw new Error("Error fetching user type");

      if (userData.type === "Mentor") {
        Swal.fire({
          title: "Mentors cannot connect with other mentors",
          icon: "error",
        });
        return;
      }

      // Check existing connection
      const { data: connectionData, error: connectionError } = await supabase
        .from("connection")
        .select("*")
        .eq("mentee", session.user.id)
        .eq("mentor", userId)
        .single();
      if (connectionError && connectionError.code !== "PGRST116") {
        throw new Error("Error checking connection");
      }

      if (connectionData) {
        Swal.fire({
          title: "You are already connected with this mentor",
          icon: "info",
        });
        return;
      }

      // If all checks pass, open the dialog to send a connection request
      setIsDialogOpen(true);
    } catch (error) {
      console.error("Error in handleConnectClick:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred. Please try again.",
        icon: "error",
      });
    }
  };

  const handleSendMessage = async () => {
    setIsSending(true);
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      const { data: connection, error: connectionError } = await supabase
        .from("connection")
        .insert([
          {
            mentee: user.id,
            mentor: userId,
            message_note: message,
          },
        ])
        .select();

      if (connectionError) throw new Error("Failed to send connection request");

      Swal.fire({
        title: "Message sent",
        text: "Your connection request has been sent successfully.",
        icon: "success",
      });

      setIsDialogOpen(false);
      setMessage("");
    } catch (error) {
      console.error(`Error sending message: ${error.message}`);
      Swal.fire({
        title: "Error",
        text: "Failed to send connection request. Please try again.",
        icon: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  if (loading)
    return (
      <div className="flex flex-col justify-center items-center min-h-screen">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        <p className="text-primary mt-2">Loading...</p>
      </div>
    );

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="pt-16 min-h-lvh">
      <div className="mx-6 md:mx-20">
        <h1 className="text-xl md:text-3xl font-bold text-center text-primary my-6">
          User profile
        </h1>
        {mentor ? (
          <div className="flex flex-col ">
            <div className=" md:mx-10 flex flex-col md:flex-row">
              <div className="flex flex-col md:w-1/2 md:max-md">
                <img
                  src={mentor.profile_pic || default_profile} // Use a default image if none
                  alt={mentor.name}
                  className="object-cover rounded-full h-52 w-52 mx-auto border-2"
                />
                <h2 className="text-center font-bold text-primary mt-4">
                  {mentor.name}
                </h2>
                <h2 className="text-center font-bold text-primary mt-4">
                  {mentor.headline}
                </h2>
                <div className="flex justify-center mt-6">
                  <button
                    className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                    onClick={handleConnectClick}
                  >
                    Connect
                  </button>
                </div>
              </div>

              <div className="md:mx-6">
                <h3 className="text-xl font-bold text-primary ">Education</h3>
                {mentor.education
                  .sort((a, b) => {
                    const rankMap = {
                      "Ph.D. ": 1, // PhD Level
                      "Pharm.D.": 1,
                      "M.D.": 2,
                      "Master's": 2, // Master's Level
                      MBA: 2,
                      MPhil: 2,
                      Mres: 2,
                      LLM: 2,
                      "Bachelor's": 3, // Bachelor's Level
                      LLB: 3,
                      "Associate's Degree": 4, // Lower qualifications
                      Diploma: 4,
                      "High school": 4,
                      GCSE: 4,
                      "AS Level": 4,
                      "A Level": 4,
                      "Professional Certificate": 4,
                      Other: 5,
                      Select: 6, // Keep "Select" at the end
                    };
                    return (rankMap[a.degree] || 7) - (rankMap[b.degree] || 7);
                  })
                  .map((edu, index) => (
                    <div
                      key={edu.id}
                      className={`flex flex-col justify-start text-sm px-4 pb-2 rounded-lg ${
                        index % 2 === 0 ? "bg-gray-100" : "bg-white"
                      }`}
                    >
                      <div className="pt-4">
                        <span className="font-semibold pr-2">
                          {edu.degree} {edu.field_of_study}{" "}
                        </span>
                      </div>
                      <div>{edu.school}</div>
                      {edu.funding && (
                        <div className="italic">
                          {edu.funding}-Funding: {edu.funding_name}
                        </div>
                      )}
                      {edu.topic && (
                        <div>
                          <span className="font-semibold pr-2 ">
                            Research/Specialisation:
                          </span>
                          {edu.topic}
                        </div>
                      )}
                      {edu.start_date && edu.end_date && (
                        <div>
                          {new Date(edu.start_date).toLocaleString("default", {
                            year: "numeric",
                            month: "long",
                          })}{" "}
                          -{" "}
                          {new Date(edu.end_date).toLocaleString("default", {
                            year: "numeric",
                            month: "long",
                          })}
                        </div>
                      )}
                    </div>
                  ))}
                <h3 className="text-xl font-bold text-primary mt-4">
                  Experience
                </h3>
                {mentor.experience.map((exp, index) => (
                  <div
                    key={exp.id}
                    className={`text-sm font-semibold my-1 py-2 px-4 rounded-lg ${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    }`}
                  >
                    {exp.job_title}
                    <span className="font-normal"> {exp.company}</span>
                    {exp.work_period && (
                      <div className="italic font-normal">
                        Work duration: {exp.work_period} months
                      </div>
                    )}
                  </div>
                ))}
                <div className="text-sm flex items-center mt-6">
                  <div className="flex items-start">
                    {mentor.website.length > 0 &&
                      mentor.website[0].linkedin && (
                        <a
                          href={mentor.website[0].linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center mb-2"
                        >
                          <img
                            src={Linkedin}
                            alt="LinkedIn"
                            className="w-6 h-6 mr-3"
                          />
                        </a>
                      )}
                    {mentor.website.length > 0 &&
                      mentor.website[0].research_url && (
                        <a
                          href={mentor.website[0].research_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center mb-2"
                        >
                          <img
                            src={Google_Scholar}
                            alt="Google Scholar"
                            className="w-6 h-6 mr-3"
                          />
                        </a>
                      )}
                    {mentor.website.length > 0 &&
                      mentor.website[0].website_url && (
                        <a
                          href={mentor.website[0].website_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center"
                        >
                          <img
                            src={Website_personal}
                            alt="Personal Website"
                            className="w-6 h-6 mr-3"
                          />
                        </a>
                      )}
                  </div>
                </div>
                {/* {console.log(menteePreferences)} */}

                {menteePreferences &&
                  menteePreferences?.preference?.length > 0 && (
                    <div className="my-10 text-sm">
                      <h3 className="text-xl font-bold text-primary">
                        Mentee Requirements
                      </h3>
                      {menteePreferences.preference[0].aspire_degree && (
                        <div className="flex flex-col">
                          {menteePreferences.preference[0].aspire_degree && (
                            <div>
                              <span className="font-semibold pr-2">
                                Target Degree:
                              </span>
                              {menteePreferences.preference[0].aspire_degree}
                            </div>
                          )}
                          {menteePreferences.preference[0].study_start && (
                            <div>
                              <span className="font-semibold pr-2">
                                Study Start:
                              </span>
                              {menteePreferences.preference[0].study_start}
                            </div>
                          )}
                          {menteePreferences.preference[0].course_decided && (
                            <div>
                              <span className="font-semibold pr-2">
                                Course Decided:
                              </span>
                              {menteePreferences.preference[0].course_decided}
                            </div>
                          )}
                          {menteePreferences.preference[0].course_name && (
                            <div>
                              <span className="font-semibold pr-2">
                                Course/Subject:
                              </span>
                              {menteePreferences.preference[0].course_name}
                            </div>
                          )}
                          {menteePreferences.preference[0].cv_link && (
                            <div>
                              <span className="font-semibold pr-2">
                                CV Link:
                              </span>
                              <a
                                href={menteePreferences.preference[0].cv_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {menteePreferences.preference[0].cv_link}
                              </a>
                            </div>
                          )}
                        </div>
                      )}

                      <ul>
                        <span className="font-semibold pr-2">
                          Preferred Universities:
                        </span>
                        {menteePreferences.university.map((uni) => (
                          <li
                            key={uni.university_id}
                            className="flex flex-col justify-start text-sm"
                          >
                            <div className="">
                              <span className="pr-2">
                                {uni.university_name}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
              </div>
            </div>

            <div className="my-10 md:mx-10">
              <div className="bg-stone-100 p-4 rounded-xl min-h-48">
                <strong>ABOUT ME:</strong>
                <div>{mentor.about_yourself}</div>
              </div>
            </div>
          </div>
        ) : (
          <p>No mentor found.</p>
        )}
      </div>
      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40">
          <div className="bg-white p-4 rounded shadow-lg mx-2 md:w-1/2">
            <h3 className="text-lg font-semibold mb-4">
              Add a note (optional)
            </h3>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="This can increase your chances of getting a response. For example, I aspire to do a master's degree in data science in the US. I have done my bachelors in mechanical engineering from Amity University, Delhi."
              className="w-full h-32 p-2 border rounded mb-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                onClick={() => setIsDialogOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark"
                onClick={handleSendMessage}
                disabled={isSending}
              >
                {isSending ? "Sending..." : "Send Request"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MentorDetails;
