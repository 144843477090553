import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import Mentors from "./pages/Mentor-related/Mentors";
import Mentorsignup from "./pages/Mentor-related/Mentorsignup";
import Beamentor from "./pages/Mentor-related/Beamentor";
import Signup from "./pages/Signup";
import Signupfilter from "./pages/Signupfilter";
import Menteesignup from "./pages/Mentor-related/Findmentorsignup";
import Whychooseus from "./pages/Aboutus/Whychooseus";
import Whoweare from "./pages/Aboutus/Whoweare";
import Whatweoffer from "./pages/Aboutus/Whatweoffer";
import FAQs from "./pages/Resources/FAQs";
import HowtowriteCV from "./pages/Resources/HowtowriteCV";
import Personalstatement from "./pages/Resources/Personalstatement";
import Letterofrecom from "./pages/Resources/Letterofrecom";
import Coverletter from "./pages/Resources/Coverletter";
import Undergraduate from "./pages/Resources/Undergraduate";
import Postgraduate from "./pages/Resources/Postgraduate";
import MentorDetails from "./pages/Mentor-related/MentorDetails";
import Your_profile from "./pages/Mentor-related/Your_profile";
import ResetPassword from "./pages/ResetPassword";
import Guideline_mentee from "./pages/Profile_related/Guideline_mentee";
import Guideline_mentor from "./pages/Profile_related/Guideline_mentor";
import Chat from "./components/Chat";
import supabase from "./config/supabaseClient";
import TermsNconditions from "./pages/TermsNconditions";
import Contactus from "./components/ContactUs";
import PricedetailsMentor from "./pages/Profile_related/PricedetailsMentor";
import PricedetailsMentee from "./pages/Profile_related/PricedetailsMentee";
import useAnalytics from "./useAnalytics";
import Blankpage from "./pages/Blankpage";
import Summerschool from "./pages/Summerschool";

// New ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  const [token, setToken] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error fetching session:", error);
      } else {
        setToken(data);
      }
    };
    fetchSession();
  }, []);

  // useAnalytics();

  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop component here */}
      <AnalyticsTracker />
      <Navbar />
      <Contactus />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mentors" element={<Mentors />} />
        <Route path="/login" element={<Login />} />
        <Route path="/beamentor" element={<Beamentor />} />
        <Route path="/mentorsignup" element={<Mentorsignup />} />
        <Route path="/summerschool" element={<Summerschool />} />
        {!token.session ? (
          <Route path="/signup" element={<Signup />} />
        ) : (
          <Route path="/signup" element={<Your_profile />} />
        )}
        <Route path="/signupfilter" element={<Signupfilter />} />
        <Route path="/menteesignup" element={<Menteesignup />} />
        <Route path="/why-choose-us" element={<Whychooseus />} />
        <Route path="/who-we-are" element={<Whoweare />} />
        <Route path="/what-we-offer" element={<Whatweoffer />} />
        <Route path="/resources/how-to-write-cv" element={<HowtowriteCV />} />
        <Route
          path="/resources/personal-statement"
          element={<Personalstatement />}
        />
        <Route
          path="/resources/letter-of-recommendation"
          element={<Letterofrecom />}
        />
        <Route path="/resources/cover-letter" element={<Coverletter />} />
        <Route path="/resources/undergrad" element={<Undergraduate />} />
        <Route path="/resources/postgrad" element={<Postgraduate />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/profile/:userId" element={<MentorDetails />} />
        <Route path="/your_profile" element={<Your_profile />} />
        {token.session ? (
          <>
            {/* eslint-disable-next-line */}
            <Route path="/guideline_mentee" element={<Guideline_mentee />} />
            {/* eslint-disable-next-line */}
            <Route path="/guideline_mentor" element={<Guideline_mentor />} />
            <Route
              path="/yourmessages"
              element={<Chat userId={token.session.user.id} />}
            />
            <Route
              path="/pricedetails_mentor"
              element={<PricedetailsMentor />}
            />
          </>
        ) : (
          <Route path="/yourmessages" element={<Blankpage />} />
        )}
        <Route path="/pricedetails_mentee" element={<PricedetailsMentee />} />
        <Route path="/terms-and-conditions" element={<TermsNconditions />} />
        <Route path="*" element={<Blankpage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

// Create a separate component for analytics tracking
const AnalyticsTracker = () => {
  useAnalytics(); // Call the custom hook to track page views
  return null; // This component does not render anything
};

export default App;
